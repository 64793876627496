

import React, { useEffect, useState } from 'react';
import useLocalStorage from "use-local-storage";
import { styled, withStyle,createThemedUseStyletron, useStyletron } from 'baseui';
import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import { Wrapper, Header, Heading } from '../../components/WrapperStyle';

import {Button, KIND} from 'baseui/button';
import { injectIntl } from 'react-intl';
import {StatefulPopover, PLACEMENT} from 'baseui/popover';
import {Pagination} from 'baseui/pagination';
import NoResult from '../../components/NoResult/NoResult';
import Input from '../../components/Input/Input';
import {StatefulMenu} from 'baseui/menu';




import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import {
  Label1,
  Label2,
  Label3,
  Label4,
  Paragraph1,
  ParagraphMedium,
  ParagraphSmall,
  ParagraphXSmall,
  H1, H4 , H5 , H6
} from 'baseui/typography';
import {
  Show ,
  Menu,
  Filter, 
  TriangleDown
} from 'baseui/icon';

import { SubTitle, Title, TitleWrapper } from '../../components/FileCard/StickerCard.style';

import {
  TableWrapper,
  StyledTable,
  StyledHeadCell,
  StyledBodyCell,

} from './Customers.style';

import useSwr from "swr";
import { ApiDfCodes } from '../../services';
require('dayjs/locale/ar');
require('dayjs/locale/fr');
require('dayjs/locale/en');

type CustomThemeT = { red400: string; textNormal: string; colors: any };

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

const Image = styled('img', () => ({
  width: '90%',
  height: 'auto',
}));






 const  DtcManual = ({intl}) => {
  let history = useHistory();
  const [css , theme2] = useStyletron();

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(100);
  const [data,setData] = useState({});






  const [ref,setRef] = useState(null);

  useEffect(()=>{
    ApiDfCodes.get(`/getAllDfs`,{params : { page : page , limit :  limit , filter : ref}}).then((res) =>{
      setData(res);
    })  ;

  },[page,limit,ref])
  




  // return data for pagination change
  const handlePageChange = (nextPage: number) => {
    if (nextPage < 1) {
      return;
    }
    if (nextPage > data?.data.numPages) {
      return;
    }
    setPage(nextPage);
  };
  const handleLimitChange = (nextLimit: number) => {
    const nextPageNum = Math.ceil(data?.data.numPages / nextLimit);
    if (nextPageNum < page) {
      setLimit(nextLimit);
      setPage(nextPageNum);
    } else {
      setLimit(nextLimit);
    }
  };
    
    


  
  const [search, setSearch] = useState([]);




  function handleSearch(event) {
    const value = event.currentTarget.value;
    setRef(value);
    setSearch(value);
    setPage(1);
  }
  const [them,setThem]=useLocalStorage("theme",'light')
  const backgroundTheme = { backgroundColor: them === 'dark' ? 'rgb( 40 40 40)' : '#ffffff',}
  const darkestBackgroundTheme = { backgroundColor: them === 'dark' ? 'rgb( 0 0 0)' : '',}
  const textTheme = { color: them === 'dark' ? '#ffffff' : '' };
  const secondaryTextTheme = { color: them === 'dark' ? 'rgb(160 160 160' : '' };
  const styleHeadTabel={backgroundColor:them==='dark'?'rgb( 0 0 0)':'',
  boxShadow: '0 0 5px rgba(170, 170 , 170, 0.5)',
  color:them==='dark'?'rgb(255,255,255)':'' };



  return (
    <Grid fluid={true}>
          <Breadcrumbs>
      <StyledLink href="/help"><FormattedMessage id = 'Help'/></StyledLink>
      <span style={secondaryTextTheme}><FormattedMessage id = 'DfToPcode'/></span>
    </Breadcrumbs>
 
  
    <Row>
        <Col md={12}>
        <Header
            style={{
              marginBottom: 30,
              boxShadow: them==='dark'?'0 0 5px rgba(255, 255 ,255, 0.05)':'0 0 5px rgba(0, 0 ,0, 0.05)',
              backgroundColor:them==='dark'?'rgb(0 0 0)':''
            }}
          >
            <Col md={4}>
              <Heading style={textTheme}><FormattedMessage id = 'DF TO PCODES'/></Heading>
            </Col>

            <Col md={8}>
              <Row>
                  <Input
                    value={search}
                    placeholder={intl.formatMessage({id : "Ex : DF00X"})}
                    onChange={handleSearch}
                    clearable
                  />

            
              </Row>
            </Col>
     
          </Header>
          
    
          <Wrapper style={{ boxShadow: '0 0 5px rgba(255, 255 , 255, 0.000005)',backgroundColor:them==='dark'?'rgb(0 0 0)':'' }}>
            <TableWrapper>
              <StyledTable style={darkestBackgroundTheme} $gridTemplateColumns="minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) ">
              <StyledHeadCell style={styleHeadTabel}><span style={textTheme}><FormattedMessage id = 'DF CODE'/></span></StyledHeadCell>
               <StyledHeadCell style={styleHeadTabel}><span style={textTheme}><FormattedMessage id = 'P CODE'/></span></StyledHeadCell>
               <StyledHeadCell style={styleHeadTabel}><span style={textTheme}><FormattedMessage id = 'Description'/></span></StyledHeadCell>

                {data ? (
                  data?.data?.data.data.length ? (
                    data?.data?.data.data
                      .map((row, index) => (
                        <React.Fragment key={index}>
                      <StyledBodyCell><span style={textTheme}>{row.dfCode}</span></StyledBodyCell>
                      <StyledBodyCell><span style={textTheme}>{row.pCode}</span></StyledBodyCell>
                      <StyledBodyCell><span style={textTheme}>{row.description}</span></StyledBodyCell>              
                        </React.Fragment>
                      ))
                  ) : (
                    <NoResult
                      hideButton={false}
                      style={{
                        gridColumnStart: '1',
                        gridColumnEnd: 'one',
                      }}
                    />
                  )
                ) : null}
              </StyledTable>
            </TableWrapper>
          </Wrapper>
    
    <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
<div
        className={css({
          paddingTop: theme2.sizing.scale600,
          paddingBottom: theme2.sizing.scale600,
          paddingRight: theme2.sizing.scale800,
          paddingLeft: theme2.sizing.scale800,
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor:them==='dark'?'#000000':''
        })}
      >
        <StatefulPopover
          content={({close}) => (
            <StatefulMenu
              items={Array.from({length: 100}, (_, i) => ({
                label: i + 1,
              }))}
              onItemSelect={({item}) => {
                handleLimitChange(item.label);
                close();
              }}
              overrides={{
                List: {
                  style: {height: '150px', width: '100px'},
                },
              }}
            />
          )}
          placement={PLACEMENT.bottom}
        >
          <Button kind={KIND.tertiary} endEnhancer={TriangleDown}>
            {`${limit} Rows`}
          </Button>
        </StatefulPopover>
        <Pagination
          currentPage={page}
          numPages={data?.data?.numPages}
          onPageChange={({nextPage}) => handlePageChange(nextPage)}
        />
      </div>
</Wrapper>
        </Col>
      </Row>
    


    </Grid>
  );
}; 
export default injectIntl (DtcManual);

